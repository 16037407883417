.newsletter{
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../../assets/images/subscribe_background.jpeg") center/cover no-repeat;
    min-height: 65vh;
}
.newsletter-form{
    max-width: 460px;
}
.newsletter-form .form-control{
    width: 100%;
    background-color: transparent;
    border: 1px solid var(--white-color);
    border-radius: 35px;
    height: 66px;
    font-size: 22px;
    padding: 0 3rem;
    margin-bottom: 2.6rem;
    color: var(--white-color);
    line-height: 66px;
    font-family: inherit;
    font-weight: 200;
}
.newsletter-form .submit-btn{
    width: 100%;
    height: 66px;
    border-radius: 35px;
    border: 2px solid var(--green-color);
    transition: var(--transition);
}
.newsletter-form .submit-btn:hover{
    background-color: transparent;
    color: var(--green-color);
}