.footer .section-title{
    margin-bottom: 0;
}
.footer.section-p{
    padding: 4.5rem 0;
}
.footer-social-links{
    margin-top: 2rem;
}
.footer-social-links li{
    margin: 0 3rem;
}
.footer .reserved-text{
    margin-top: 2rem;
}

.footer-content svg {
  font-size: 30px; /* Adjust the size as needed */
}

.phone-number {
    display: flex;
    align-items: center;
  }
  
  .phone-number a {
    display: flex;
    align-items: center;
    text-decoration: none; /* If you want to remove the underline from the phone number */
  }
  
  .phone-number span {
    margin-left: 5px; 
  }

  .contact-info {
    text-align: right;
  }
  
  .contact-info p {
    margin: 5px 0;
    color: #fff;
  }